<template>
  <div style="background-color: black">
    <van-nav-bar
          title="分享海报"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
    />
    <van-config-provider :theme-vars="themeVars">
      <div class="container" style="background-color: black">
        <van-image
            position="center"
            :src="posterUrl"
        />
        <van-button round type="primary" style="margin-top: -100px;">长按保存图片</van-button>
      </div>
    </van-config-provider>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { queryOrderType, memberOrder, payOrderFinishQuery } from '@/api/home'
import QRCode from 'qrcodejs2'
import { showNotify } from 'vant'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import exclusive from './images/exclusive.png'
import delay from './images/delay.png'
import infinite from './images/infinite.png'
import kf from './images/kf.png'

export default {
  name: 'poster',
  setup () {
    const onClickLeft = () => history.back();
    const store = useStore()
    const active = ref(1)
    const route = useRoute()
    let invokeCount = 0
    const state = reactive({
      level: computed(() => store.getters.roles),//用户角色
      users: store.getters.users,//用户名
      expiretime: computed(() => store.getters.expiretime),//会员到期时间
      residualDegree: computed(() => store.getters.residualDegree),//非会员剩余几次
      posterUrl: computed(() => store.getters.posterUrl)
    })
    const posterUrl = state.posterUrl
    const methods = reactive({
      // 获取订单类型 1个月还是多个月
      //保留
      loadingQueryOrderType () {
        queryOrderType().then(res => {
          state.vipMoney = res.data
          state.vipMoneySelected = res?.data[0]?.id
        })
      },
      // 完成支付
      //保留
      finishOrder () {
        state.codeImg = false
        state.userApply = false
        showNotify({ type: 'success', message: '支付成功' })
        methods.refreshGetInfo()
      },
      // 刷新用户信息
      //保留
      refreshGetInfo () {
        state.time888 = new Date()
        store.dispatch('GetInfo').then(() => {})
      },
      // 判断路由变化http://192.168.3.8:2021/#/home?orderId=3066428391642103808&paytype=1
      //保留
      routerChange () {
        if (route.query.orderId && route.query.paytype) {
          state.orderId = route.query.orderId
          methods.userApplyFun()
        }
      },
      // 关闭弹框
      //保留
      handleClose () {
        invokeCount = 0
        methods.cleanTimer()
        state.codeImg = false
        methods.refreshGetInfo()
      },
      //用户主动点击我已支付按钮
      //保留
      userApplyFun () {
        payOrderFinishQuery(state.orderId).then(res => {
          state.userApply = false
          if (res.data) {
            methods.finishOrder()
          } else {
            showNotify({ type: 'warning', message: '支付失败，若已完成支付请联系客服' })
          }
        })
      }
    })
    onMounted(() => {
      methods.loadingQueryOrderType()
      methods.routerChange()
      //监听页面状态
      document.addEventListener("visibilitychange", () => {
        if(document.hidden) {
          // 页面被挂起
          state.time888="页面被挂在"
        }
        else {
          //更新用户信息
          methods.refreshGetInfo()
          // 页面呼出
        }
      });
    })
    const themeVars = reactive({
      navBarBackground: 'hsla(0, 0%, 0%, 0.8)',
      navBarTitleTextColor: '#FFFAFA',
      navBarHeight: '0.6rem',
      navBarTitleFontSize: '0.2rem',
      LineHeightLg: '0.6rem'
    })
    const shareCount = 1
    const signCount = 1
    return {
      active,
      ...toRefs(state),
      ...toRefs(methods),
      onClickLeft,
      themeVars,
      shareCount,
      signCount,
      posterUrl
    }
  }
}
</script>
<style scoped lang="less">
@import "./common";
.homeScroll{
  overflow-y: auto;
}
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.notice-swipe {
  height: 20px;
  line-height: 20px;
}

.van-col-1{
  background-color: #5b9ef7;
}

.van-col-2{
  background-color: #3bcb73;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

/deep/ .van-nav-bar__title {
  max-width: unset;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
